// export const BASE_URL = "http://localhost:7432/"
// export const BASE_URL = "https://192.168.1.5:7432/"

// export const BASE_URL = "http://62.72.30.137:7432/"

// export const BASE_URL = "http://213.210.36.202:7432/"
export const BASE_URL = "https://happeningbazar.in:7432/"
// export const BASE_URL = "https://192.168.1.5:7432/"


