import React, { useEffect, useState } from 'react'
// import Sidebaar from '../../Component/Sidebar'
import { Form, FormGroup } from 'react-bootstrap'
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import TextField from '@mui/material/TextField';
import {
    CountrySelect,
    StateSelect,
} from "react-country-state-city";
// import dayjs from 'dayjs';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import axios from 'axios';

import { toast } from 'react-toastify';
// import Loader from '../../Component/Loader';
import { useLocation } from 'react-router-dom';
import { getwithheader, postwithheader, putwithheader } from '../../Api/Apis';

function AddEvent() {
    const { state } = useLocation()
    const [statedata, setstatedata] = useState("")
    const [title, settitle] = useState("")
    const [shortdetail, setshortdetail] = useState("")
    const [file, setfile] = useState(null)
    const [date, setDate] = useState();
    const [amount, setamount] = useState("0")
    const [detail, setdetail] = useState("")
    const [loading, setloading] = useState(false)
    const [store, setstore] = useState("")
    const [storedata, setstoredata] = useState([])
    const [starttime, setstarttime] = useState("")
    const [endtime, setendtime] = useState("")

    let token = localStorage.getItem("token")
    let userid = localStorage.getItem("userid")

    const handledetail = (event, editor) => {
        const data = editor.getData();
        setdetail(data);
    };




    const handleimage = (e) => {
        let selectedfile = e.target.files[0]
        setfile(selectedfile)
    }

    useEffect(() => {
        if (state) {


            settitle(state.title)
            // console.log(state.)
            setshortdetail(state.short_detail)
            setdetail(state.detail)
            setstarttime(state.start_time)
            setstatedata(state.state)


            setendtime(state.end_time)

            setDate(state.date)


        }
    }, [state]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        setloading(true)
        let formdata = new FormData()
        if (state) {
            formdata.append("id", state._id)
        }
        formdata.append("title", title)
        formdata.append("amount", amount)
        formdata.append("store", store)
        if (file) {
            formdata.append("image", file)
        }
        formdata.append("date", date)
        formdata.append("state", statedata)
        formdata.append("start_time", starttime)
        formdata.append("end_time", endtime)
        formdata.append("short_detail", shortdetail)
        formdata.append("detail", detail)
        formdata.append("created_by", userid)
        if (state) {
            const res = await putwithheader('events', token, formdata)
            console.log(res)
            if (res.error == "0") {
                toast.success(res.message)
                setloading(false)
            } else {
                toast.error(res.message)
                setloading(false)
            }
        } else {
            const res = await postwithheader('events', token, formdata)
            if (res.error == "0") {
                toast.success(res.message)
                setloading(false)
            } else {
                toast.error(res.message)
                setloading(false)
            }
        }
    }

    const handlestore = async () => {
        let res = await getwithheader(`vendorstore/${userid}`, token)
        setstoredata(res.data)
    }

    useEffect(() => {
        handlestore()
    }, [])




    return (
        <>
            {/* {loading && <Loader />} */}

            <>
                <section>
                    <div className="container">
                        <Form onSubmit={handleSubmit}>
                            <div className="row gy-4">
                                {state &&
                                    <div className="col-md-4">


                                        <>
                                            <label htmlFor="">Selected State</label>
                                            <input value={statedata} className='form-control ' disabled></input>
                                        </>
                                    </div>
                                }
                                <div className="col-md-4">

                                    <FormGroup className=''>
                                        <label htmlFor="">Select Event State</label>
                                        <StateSelect
                                            countryid={101}
                                            value={statedata}
                                            onChange={(e) => setstatedata(e.name)}
                                            placeHolder="Select State"
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-md-4">
                                    <FormGroup>
                                        <label htmlFor="">Title</label>
                                        <input type="text" required className='form-control' value={title} onChange={(e) => settitle(e.target.value)} />

                                    </FormGroup>
                                </div>
                                <div className="col-md-4">
                                    <FormGroup>
                                        <label htmlFor="">Image</label>
                                        <input
                                            type="file"

                                            className="form-control"
                                            onChange={(e) => handleimage(e)}
                                        />
                                    </FormGroup>
                                </div>
                                {/* <div className="col-md-4">
                                        <label htmlFor="">Store</label>
                                        <select name="" value={store} onChange={(e) => setstore(e.target.value)} className='form-control form-select' id="">
                                            <option value="">Select Store</option>
                                            {storedata.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item._id}>{item.title}</option>
                                                    </>
                                                )
                                            })}
                                        </select>
                                    </div> */}
                                <div className="col-md-4">
                                    <FormGroup>
                                        <label htmlFor="">Short Detail</label>
                                        <input type="text" required className='form-control' value={shortdetail} onChange={(e) => setshortdetail(e.target.value)} />
                                    </FormGroup>
                                </div>
                                <div className="col-md-4">
                                    <FormGroup className="w-100">
                                        <label htmlFor="eventDateTime">Choose Date  for Events</label>
                                        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} className='form-control' />

                                    </FormGroup>

                                </div>
                                <div className="col-md-4">
                                    <FormGroup className="w-100">
                                        <label htmlFor="eventDateTime">Choose Start Time  for Events</label>
                                        <input type="time" value={starttime} onChange={(e) => setstarttime(e.target.value)} className='form-control' />
                                    </FormGroup>

                                </div>
                                <div className="col-md-4">
                                    <FormGroup className="w-100">
                                        <label htmlFor="eventDateTime">Choose End Time  for Events</label>
                                        <input type="time" value={endtime} onChange={(e) => setendtime(e.target.value)} className='form-control' />
                                    </FormGroup>

                                </div>
                                <div className="col-md-4">
                                    <FormGroup>
                                        <label htmlFor="">Price</label>
                                        <input type="text" required className='form-control' value={amount} onChange={(e) => setamount(e.target.value)} />

                                    </FormGroup>
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="detail">Detail</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={detail}
                                        onChange={handledetail}
                                        className="custom-ckeditor"
                                        id="detail"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <button className='btn bg-yellow text-white'>Submit</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </section>
            </>

        </>
    )
}

export default AddEvent

