import React, { useEffect, useState } from 'react';
import { deleteapi, getwithheader, postapiwithoutheader, putwithheader } from '../../Api/Apis';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Form } from 'react-bootstrap';
import { BASE_URL } from '../../URL';

function MembershipPage() {
    const token = localStorage.getItem("token");
    const [data, setdata] = useState([]);
    const [file, setfile] = useState("");
    const [name, setname] = useState("");
    const [amount, setamount] = useState("");
    const [description, setdescription] = useState("");
    const [loading, setloading] = useState(false);
    const [editid, seteditid] = useState("");

    const handlefile = (e) => {
        e.preventDefault();
        let selectedfile = e.target.files[0];
        setfile(selectedfile);
    };

    const handlesubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", name);
        formData.append("description", description);
        formData.append("image", file);
        formData.append("amount", amount);
        if (!editid) {


            try {
                let res = await postapiwithoutheader(`membership`, formData);
                console.log(res);
                if (res.error === 0) {
                    toast.success(res.message);
                    handleget(); // refresh data
                } else {
                    toast.error("Data not submitted");
                }
            } catch (error) {
                console.error("Error:", error);
                toast.error("Error creating membership");
            }
        } else {

            const res = await putwithheader(`membership/${editid}`, formData, token)
            if (res.error == "0") {
                toast.success(res.message)
                handleget()
                seteditid("")
                setloading(false)
            } else {
                toast.error("Data not Submit")
                setloading(false)
            }
        }

    };
    const handledelte = async (e, id) => {
        e.preventDefault();

        // Show confirmation alert
        const isConfirmed = window.confirm("Are you sure you want to delete this membership?");
        if (!isConfirmed) {
            return; // Exit if the user cancels
        }

        setloading(true);

        try {
            // Call the deleteapi function with the endpoint and token
            const res = await deleteapi(`membership/${id}`);

            if (res.error === "0") {
                toast.success("Membership deleted successfully"); // Notify user
                handleget(); // Fetch updated data after deletion
            } else {
                toast.error("Failed to delete membership"); // Notify user about the error
            }
        } catch (error) {
            toast.error("An unexpected error occurred."); // Handle unexpected errors
        } finally {
            setloading(false);
        }
    };
    const handleedit = (e, item) => {
        e.preventDefault()
        setname(item.name)
        setdescription(item.description)
        seteditid(item._id)
        setamount(item.amount)
    }

    const columns = [
        {
            field: "image",
            headerName: "Image",
            flex: 1,
            renderCell: (params) => {
                return (
                    <img
                        src={`${BASE_URL}${params.row.image}`}
                        alt=""
                        style={{ height: '50px', borderRadius: '50%' }}
                    />
                );
            },
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "amount",
            headerName: "Amount",
            flex: 1,
        },
        {
            field: "description",
            headerName: "Description",
            flex: 1,
        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="d-flex gap-3 mt-1">

                        <button className="btn bg-yellow text-white" onClick={(e) => handledelte(e, params.row._id)}>
                            <i className="fa-solid fa-trash"></i>
                        </button>
                    </div>
                );
            },
        },
        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="d-flex gap-3 mt-1">
                        <button className="btn bg-yellow text-white" onClick={(e) => handleedit(e, params.row)}>
                            <i className="fa-solid fa-pen-to-square"></i>
                        </button>

                    </div>
                );
            },
        }
    ];

    const handleget = async () => {
        let res = await getwithheader(`membership`, token);
        console.log(res);
        setdata(res.data);
    };

    useEffect(() => {
        handleget();
    }, []);

    const getRowId = (row) => row._id;

    return (
        <>
            <section>
                <div className="container">
                    <Form onSubmit={handlesubmit}>
                        <div className="row">
                            <h2 className="text-center mt-2">Create Membership</h2>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Image</label>
                                    <input
                                        type="file"
                                        name="image"
                                        className="form-control"
                                        onChange={handlefile}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Membership Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        value={name}
                                        onChange={(e) => setname(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Amount</label>
                                    <input
                                        type="text"
                                        name="amount"
                                        className="form-control"
                                        value={amount}
                                        onChange={(e) => setamount(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Description</label>
                                    <input
                                        type="text"
                                        name="description"
                                        className="form-control"
                                        value={description}
                                        onChange={(e) => setdescription(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <button type="submit" className="btn bg-yellow">
                                    Submit Membership
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </section>
            <section className="bg-body-tertiary" style={{ overflow: "hidden" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Box>
                                <DataGrid rows={data} columns={columns} getRowId={getRowId} />
                            </Box>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default MembershipPage;