import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from 'react-loader-spinner';

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { BASE_URL } from "../../URL";
import { deleteapi, putwithheader } from "../../Api/Apis";
import { useLocation, useNavigate } from "react-router-dom";


function SubCategory() {
  const navigate = useNavigate();

  const [loading, setloading] = useState(false)

  const { state } = useLocation()




  let token = localStorage.getItem("paneltoken")
  const [select, setselect] = useState("")
  const [description, setdescription] = useState("")
  const [title, settitle] = useState("")
  const [type, settype] = useState("")
  const [image, setimage] = useState([])
  const [Categories, setcategories] = useState("")
  const [editid, seteditid] = useState("")
  const [editimage, seteditimage] = useState([])


  const [category, setcategory] = useState([]);
  const [subcategory, setsubcategory] = useState([])


  const headers = {
    Authorization: `Bearer ${token}`

  }



  const handlecategory = () => {
    axios.get(`${BASE_URL}category`, { headers }).then((res) => {
      console.log(res.data.data)
      setcategory(res.data.data)
    })
  }

  const handlesubcategory = () => {
    axios.get(`${BASE_URL}subcategory`, { headers }).then((res) => {
      console.log("subcategory", res.data.data)
      setsubcategory(res.data.data)
    })
  }

  useEffect(() => {
    handlecategory()
    handlesubcategory()
  }, [])

  const handlesubmit = async (e) => {
    e.preventDefault();
    setloading(true); // Start loading before processing

    // Reset form fields
    setselect("");
    setdescription("");
    settitle("");
    settype("");
    setimage("");

    let formdata = new FormData();

    // Append common fields
    formdata.append("category", Categories);
    formdata.append("description", description);
    formdata.append("title", title);
    formdata.append("type", type);

    // Append images to form data
    if (image && image.length > 0) {
      image.forEach((img, index) => {
        formdata.append(`image`, img); // Append each image
      });
    }

    try {
      if (!editid) {
        // Create new subcategory
        const response = await axios.post(`${BASE_URL}subcategory`, formdata, { headers });
        console.log(response);
        if (response.data.status === "OK") {
          toast.success("Data submitted successfully");
          handlesubcategory(); // Call function to refresh subcategory list
        }
      } else {
        // Update existing subcategory
        const res = await putwithheader(`subcategory/${editid}`, formdata, token);
        if (res.error == "0") {
          toast.success(res.message);
          handlesubcategory(); // Call function to refresh subcategory list
          navigate(-1)
          seteditid(""); // Reset edit ID
        } else {
          toast.error("Data not submitted");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error submitting data. Please try again later.");
    } finally {
      setloading(false); // Stop loading after process is complete
    }
  };


  const onOptionChangeHandler = (event) => {
    setcategories(event.target.value);
  };

  const handleimage = (e) => {
    e.preventDefault();
    const selectedFiles = e.target.files; // Get the FileList object

    // Convert FileList to an array to handle multiple files
    const filesArray = Array.from(selectedFiles);

    // If you need to store the images in state
    setimage(filesArray); // Assuming setImages is a state setter for an array
  };

  const handleDelete = async (e, id) => {
    setloading(true)
    e.preventDefault()
    const data = await deleteapi(`deletesubcategory/${id}`, token)
    console.log("data", data)
    if (data.status == "OK") {
      toast.success(data.message)
      handlesubcategory()
      setloading(false)
    }
  }




  const handleEdit = () => {

    setcategories(state.category?._id)
    settitle(state.title)
    settype(state.type)
    setdescription(state.description)
    seteditid(state?._id)
    seteditimage(state.image)

    // console.log(item)

  }

  useEffect(() => {
    if (state) {
      handleEdit()
    }
  }, [state])

  const handleDeleteImage = async (e, imgid) => {
    e.preventDefault();

    try {
      // Call the delete API
      const response = await deleteapi(`subcategoryimage/${imgid}`);
      console.log(response);

      if (response.error == "0") {
        // If the deletion was successful, filter out the deleted image
        seteditimage(prevImages => prevImages.filter(item => item?._id !== imgid));
        toast.success(response.message)
      } else {
        // Handle failure response
        console.error("Failed to delete image:", response.message);
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  return (
    <>

      <ToastContainer />

      {loading && <div className="overlay"> (<Bars
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />) </div>}

      <section className="bg-body-tertiary">
        <div className="container-fluid">
          <h3 className="heading">SUB-CATEGORY</h3>
          <Form onSubmit={(e) => handlesubmit(e)}>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Select Category</label>
                  {/* <label htmlFor="">Select Category</label>
                  <input type="select" className="form-control" placeholder="Select Category" required value={select} onChange={(e) => setselect(e.target.value)} /> */}
                  <select className="form-control form-select" value={Categories} onChange={(e) => setcategories(e.target.value)}>
                    <option>Select Category</option>
                    {category.map((item) => {
                      return (
                        <>
                          <option value={item?._id}>{item.name}</option>
                        </>
                      )
                    })}
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Title</label>
                  <input type="text" className="form-control" placeholder="Title" required value={title} onChange={(e) => settitle(e.target.value)} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Type</label>
                  <input type="text" className="form-control" placeholder="Type" required value={type} onChange={(e) => settype(e.target.value)} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Image</label>
                  <input type="file" multiple className="form-control" onChange={(e) => handleimage(e)} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Description</label>
                  <input type="text" className="form-control" placeholder="Description" required value={description} onChange={(e) => setdescription(e.target.value)} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">

                  {editimage.map((item) => (
                    <div className="col-md-2" key={item?._id}>
                      <div className="position-relative">
                        <div className="form-group">
                          <img
                            src={`${BASE_URL}${item.img}`}
                            style={{ objectFit: "cover" }}
                            className="img-fluid"
                            alt=""
                          />
                          <button
                            className="bg-yellow btn text-white"
                            onClick={(e) => handleDeleteImage(e, item?._id)}
                            style={{ position: "absolute", top: "0", right: "10px" }}
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <Button className="btn bg-yellow mx-3 mt-2 bg" type="submit" >{editid ? "Update" : "Submit"}</Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </section>

      {/*  */}


    </>
  );

};



export default SubCategory;