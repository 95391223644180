import React, { useEffect, useState } from 'react'
import TitleComp from '../../component/TitleComp'
import { getwithheader } from '../../Api/Apis'
import { Table } from 'react-bootstrap'
import moment from 'moment/moment'
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'

const Offerlist = () => {
    const navigate = useNavigate();
    const [data, setdata] = useState([])
    const [show, setShow] = useState(false);
    const [codedata, setcodedata] = useState([])
    const handleget = async () => {
        const res = await getwithheader('alloffer')
        console.log(res.data)
        setdata(res.data)
    }
    useEffect(() => {
        handleget();
    }, []);
    const getRowId = (row) => row._id
    const columns = [
        {
            field: "name",
            headerName: "Store Name",
            flex: 1,
        },
        // {
        //     field: "code",
        //     headerName: "Code",
        //     flex: 1,
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 <button className='btn actionbtn  text-white ' onClick={(e) => { setShow(true); setcodedata(params.row.generated_codes) }}>Code</button>
        //             </>
        //         )
        //     }
        // },
        {
            field: "description",
            headerName: "Description",
            flex: 1,
        },
        {
            field: "start_date",
            headerName: "Start Date",
            flex: 1,
            renderCell: (params) => {
                return (moment(params.row.createdAt).format("DD MMMM YYYY") ?? "")
            }
        },
        {
            field: "expiry_date",
            headerName: "Expiry Date",
            flex: 1,
            renderCell: (params) => {
                return (moment(params.row.createdAt).format("DD MMMM YYYY") ?? "")
            }
        },
        {
            field: "createdAt",
            headerName: "createdAt",
            flex: 1,
            renderCell: (params) => {
                return (moment(params.row.createdAt).format("DD MMMM YYYY") ?? "")
            }
        },
        // {
        //     field: "action",
        //     headerName: "Action",
        //     flex: 1,
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 <button className='btn actionbtn me-3' onClick={(e) => handleedit(e, params.row)}><i class="fa-solid fa-pen-to-square"></i></button>
        //                 <button className='btn actionbtn' onClick={(e) => handledelete(e, params.row._id)}><i class="fa-solid fa-trash"></i></button>
        //             </>
        //         )
        //     }
        // },
        {
            field: "",
            headerName: "Details",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <button
                            className="accepted btn bg-yellow text-white"
                            onClick={() => navigate(`/offerdetail/${params.row.created_by}`, { state: params.row })}
                        >
                            <i class="fa-solid fa-eye"></i>
                        </button>

                    </>
                )
            }
        },

    ]
    return (
        <div>
            <>
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center">
                            <TitleComp title="Offer List" />
                        </div>
                        <div className="col-md-12 px-2">
                            <Box>
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    getRowId={getRowId}
                                />

                            </Box>

                        </div>
                    </div>
                </div>

            </>
        </div>
    )
}

export default Offerlist
