import React, { Children, useEffect, useState } from "react";
import logo from "../assests/logo.png"
import download from "../assests/download.png";
import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaRegChartBar,
    FaCommentAlt,
    FaShoppingBag,
    FaThList,
    FaQuestion,
    FaBloggerB
} from "react-icons/fa";
import { SiGoogleads } from "react-icons/si";
import { MdEventNote, MdLocalOffer, MdOutlineAppRegistration, MdOutlinePolicy } from "react-icons/md";
import { VscSymbolEnumMember } from "react-icons/vsc";

import { NavLink, redirect, useLocation, useNavigate } from "react-router-dom";
import Login from "../AuthPage/Login.jsx";
import { ToastContainer, toast } from "react-toastify";
import { LuListMinus, LuLogOut } from "react-icons/lu";
import axios from "axios";
import { CiLocationArrow1, CiViewList } from "react-icons/ci";
import { BiCategoryAlt, BiUser } from "react-icons/bi";
import { PiFlagBanner } from "react-icons/pi";


const Sidebar = ({ children }) => {
    const [isOpen, setIsOpen] = useState(true);
    // const [loginpage, setloginpage] = useState(false);
    // const [show, setshow] = useState(false)
    const toggle = () => setIsOpen(!isOpen);

    const location = useLocation()
    const login = location.pathname === "/"
    const navigate = useNavigate()

    const [activeMenu, setActiveMenu] = useState(null);

    const handleMenuClick = (name) => {
        setActiveMenu(activeMenu === name ? null : name);
    };





    const [showForm, setShowForm] = useState(false);

    const showForms = () => {
        setShowForm(!showForm);
    }


    const menuItem = [
        // {
        //     path: "/dashboard",
        //     name: "Dashboard",
        //     icon: <FaTh />
        // },
        {
            path: "/banner",
            name: "Banner",
            icon: <PiFlagBanner />
        },
        {
            path: "/userlist",
            name: "Userlist",
            icon: <BiUser />
        },
        // {
        //     path: "/membership",
        //     name: "Membership",
        //     icon: <VscSymbolEnumMember />
        // },
        {
            path: "/event-registration",
            name: "Event Registration",
            icon: <MdOutlineAppRegistration />
        },
        {
            path: "/addevent",
            name: "Add Event",
            icon: <MdEventNote />
        },
        {
            path: "/addlist",
            name: "Event List",
            icon: <LuListMinus />
        },
        {
            path: "/offer",
            name: "Offer List",
            icon: <MdLocalOffer />
        },
        {
            path: "/couponslist",
            name: "Coupons List",
            icon: <CiViewList />
        },
        {
            path: "/faq",
            name: "Faq",
            icon: <FaQuestion />
        },
        {
            path: "/addpolicy",
            name: "Policy",
            icon: <MdOutlinePolicy />
        },
        {
            name: "Categories",
            icon: <BiCategoryAlt />,
            path: "/categories",
            subItems: [
                {
                    path: "/categories",
                    name: "Category"
                },
                {
                    path: "/subcategory",
                    name: "SubCategory",
                    // icon: <FaRegChartBar />
                },
                {
                    path: "/subcategorylist",
                    name: "SubCategory List",
                    // icon: <FaRegChartBar />
                }
            ]
        },
        {
            path: "/blogs",
            name: "Blog",
            icon: <FaBloggerB />
        },
        {
            path: "/leads",
            name: "Leads",
            icon: <SiGoogleads />
        },
        {
            path: "/logout",
            name: "Logout",
            icon: <LuLogOut />
        }
    ];


    const handlelogout = (e) => {
        e.preventDefault()
        localStorage.clear()

        toast.success("You are Successfully Logout")
        setTimeout(() => {
            navigate('/')
        }, 700);
    }
    return (

        <>
            <ToastContainer />
            {login ? <Login /> : <div className="sidebarmain d-flex" style={{ background: "#000" }}>
                <div style={{ width: isOpen ? "300px" : "70px" }} className="sidebar position-relative">
                    <div className="top_section shadow">
                        <img style={{ display: isOpen ? "block" : "none" }} src={logo} alt="" />
                        {/* <h1 style={{display: isOpen ? "block" : "none"}} className="logo">Logo</h1> */}
                        <img style={{ marginLeft: isOpen ? "50px" : "20px", display: isOpen ? "none" : "block" }} src={download} alt="" className="icon ccc" />
                    </div>


                    {
                        menuItem.map((item, index) => (
                            <div key={index}>
                                <NavLink
                                    to={item.path || "#"}
                                    className="link"

                                    onClick={(e) => item.name === "Logout" ? handlelogout(e) : item.subItems ? handleMenuClick(item.name) : null}
                                >
                                    <div className="icon">{item.icon}</div>
                                    <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                                </NavLink>
                                {
                                    item.subItems && activeMenu === item.name && (
                                        <div className="sub-menu">
                                            {
                                                item.subItems.map((subItem, subIndex) => (
                                                    <NavLink
                                                        to={subItem.path}
                                                        key={subIndex}
                                                        className="link sub-link"
                                                        activeClassName="active"
                                                    >
                                                        <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{subItem.name}</div>
                                                    </NavLink>
                                                ))
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        ))
                    }


                </div>
                <main className="bg-white">
                    <div style={{ height: "70px" }} className="bg-body-white bg-white">
                        {isOpen ? <div style={{ marginLeft: isOpen ? "0px" : "0px" }} className="bars shadow">
                            <FaBars onClick={toggle} />

                        </div> : <div style={{ marginLeft: isOpen ? "0px" : "0px", }} className="bars shadow" >
                            <i class="fa-solid fa-xmark" onClick={toggle}></i>
                        </div>}
                    </div>

                    <div className="main pt-5">
                        {children}
                    </div>
                </main>
            </div>}
        </>




    );
};

export default Sidebar;

