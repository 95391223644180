import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { getwithheader } from '../../Api/Apis'
import { BASE_URL } from '../../URL'
import axios from 'axios'
import { toast } from 'react-toastify'

const Lead = () => {
    let token = localStorage.getItem("paneltoken")
    const [data, setdata] = useState([])
    const [loading, setloading] = useState(false)
    const handleget = async () => {
        const res = await getwithheader('leads', token)
        console.log(res.data)
        setdata(res.data)


    }
    const handledelte = (e, id) => {
        setloading(true)
        e.preventDefault()
        const isConfirmed = window.confirm("Are you sure you want to delete this category?");
        if (!isConfirmed) {
            return;
        }
        axios.delete(`${BASE_URL}leads/${id}`,).then((res) => {


            if (res.data.error == "0") {
                setloading(false)
                toast.success(res.data.message)
                handleget()
            }
        })
    }

    useEffect(() => {
        handleget()
    }, [])

    const columns = [
        {
            field: "firstName",
            headerName: "First Name",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <div style={{
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    display: 'block',
                }}>
                    {params.row.firstName}
                </div>
            ),

        },
        {
            field: "lastName",
            headerName: "Last Name",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <div style={{
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    display: 'block',
                }}>
                    {params.row.lastName}
                </div>
            ),
        },

        {
            field: "email",
            headerName: "Email",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <div style={{
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    display: 'block',
                }}>
                    {params.row.email}
                </div>
            ),
        },
        {
            field: "phone",
            headerName: "Phone",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <div style={{
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    display: 'block',
                }}>
                    {params.row.phone}
                </div>
            ),

        },
        {
            field: "city",
            headerName: "City",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <div style={{
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    display: 'block',
                }}>
                    {params.row.city}
                </div>
            ),

        },
        {
            field: "country",
            headerName: "Country",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <div style={{
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    display: 'block',
                }}>
                    {params.row.country}
                </div>
            ),

        },
        {
            field: "pincode",
            headerName: "Pincode",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <div style={{
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    display: 'block',
                }}>
                    {params.row.pincode}
                </div>
            ),

        },
        {
            field: "state",
            headerName: "State",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <div style={{
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    display: 'block',
                }}>
                    {params.row.state}
                </div>
            ),

        },
        {
            field: "type",
            headerName: "Type",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <div style={{
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    display: 'block',
                }}>
                    {params.row.type}
                </div>
            ),

        },
        {
            field: "categoryName",
            headerName: "Category Name",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <div style={{
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    display: 'block',
                }}>
                    <p>{params.row.category?.name}</p>
                </div>
            ),

        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            minWidth: 100,
            renderCell: (params) => {
                return (
                    <>
                        <div className='d-flex gap-2 mt-1'>
                            <button className='accepted btn bg-yellow text-white' onClick={(e) => handledelte(e, params.row._id)} ><i class="fa-solid fa-trash"></i></button>
                        </div>
                    </>
                )
            }

        }
        // {
        //     field: "banner", 
        //     headerName: "Banner",
        //     flex: 1,
        //     minWidth: 200, 
        //     renderCell: (params) => (
        //         <div style={{
        //             whiteSpace: 'nowrap',
        //             wordWrap: 'break-word',
        //             overflowWrap: 'break-word',
        //             wordBreak: 'break-word',  
        //             display: 'block',         
        //         }}>
        //            <img
        //             src={`${BASE_URL}${params.row.category?.banner}`}
        //             alt="Banner"
        //             style={{ width: "40px", height: "40px", objectFit: "cover", borderRadius:"50%" }}
        //         />
        //         </div>

        //     ),
        // },
        // {
        //     field: "image", 
        //     headerName: "Image",
        //     flex: 1,
        //     minWidth: 200, 
        //     renderCell: (params) => (
        //         <div style={{
        //             whiteSpace: 'nowrap',
        //             wordWrap: 'break-word',
        //             overflowWrap: 'break-word',
        //             wordBreak: 'break-word',  
        //             display: 'block',         
        //         }}>
        //           <img
        //             src={`${BASE_URL}${params.row.category?.image}`}
        //             alt="Image"
        //             style={{ width: "40px", height: "40px", objectFit: "cover", borderRadius:"50%" }}
        //         />
        //         </div>

        //     ),

        // },
        // {
        //     field: "icon", 
        //     headerName: "Icon",
        //     flex: 1,
        //     minWidth: 200, 
        //     renderCell: (params) => (
        //         <div style={{
        //             whiteSpace: 'nowrap',
        //             wordWrap: 'break-word',
        //             overflowWrap: 'break-word',
        //             wordBreak: 'break-word',  
        //             display: 'block',         
        //         }}>
        //           <img
        //             src={`${BASE_URL}${params.row.category?.icon}`}
        //             alt="Image"
        //             style={{ width: "40px", height: "40px", objectFit: "cover", borderRadius:"50%" }}
        //         />
        //         </div>

        //     ),
        // },
        // {
        //     field: "position", 
        //     headerName: "Position",
        //     flex: 1,
        //     minWidth: 200, 
        //     renderCell: (params) => (
        //         <div style={{
        //             whiteSpace: 'nowrap',
        //             wordWrap: 'break-word',
        //             overflowWrap: 'break-word',
        //             wordBreak: 'break-word',  
        //             display: 'block',         
        //         }}>
        //             {params.row.category?.position}
        //         </div>
        //     ),
        // },





    ]
    const getRowId = (row) => row._id
    return (
        <div>
            <>
                {/* <section className="bg-body-tertiary" style={{ overflow: "hidden" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <Box>
                                        <DataGrid
                                            rows={data}
                                            columns={columns}
                                            getRowId={getRowId}
                                        />

                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="bg-body-tertiary" style={{ overflow: "hidden" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 table-responsive">
                                <Box sx={{ width: '100%', overflow: 'auto' }}>
                                    <DataGrid
                                        rows={data}
                                        columns={columns}
                                        getRowId={getRowId}
                                        autoHeight
                                    />
                                </Box>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </div>
    )
}

export default Lead

