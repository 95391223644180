import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import moment from 'moment/moment'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../URL'
import { getwithheader, postwithheader } from '../../Api/Apis'

const Offerdetails = () => {
    const location = useLocation();
    const { state } = location; // Destructure state object
    // let token = localStorage.getItem("token")
    // let userid = localStorage.getItem("userid")
    const [loading, setloading] = useState(false)
    // const { url } = useParams()
    const [data, setdata] = useState([]);
    // const handleget = async () => {
    //     setloading(true)
    //     let res = await getwithheader(`getoffer`)

    //     console.log(res.data.error)
    //     if (res.data.error == "0") {
    //         setdata(res.data.data)
    //         console.log(data)
    //         setloading(false)
    //     } else {
    //         setloading(false)
    //     }
    // }
    const handlegetcoupons = async () => {
        setloading(true)
        let res = await getwithheader(`generate-code?status=expired&user=${state.created_by}`)

        console.log(res.data.error)
        if (res.data.error == "0") {
            setdata(res.data.data)
            console.log(data)
            setloading(false)
        } else {
            setloading(false)
        }
    }


    // const handlegenratecode = async (e) => {
    //     e.preventDefault()
    //     if (isLoggedIn) {
    //         let requestdata = {
    //             user: userid,
    //             vendor: data[0]?.created_by,
    //             offerId: data[0]?._id
    //         }
    //         let res = await postwithheader(`generate-code`, requestdata, token)
    //         if (res.error == 0) {
    //             setshow(true)
    //             setcodedata(res)

    //         } else {
    //             toast.error(res.message)
    //         }
    //     } else {
    //         setShowLoginModal(true); // Show login modal if not logged in
    //     }
    // }
    useEffect(() => {
        // handleget()
        handlegetcoupons()
    }, [])


    return (
        <>


            <section className='offerdetails'>
                <div className="container">
                    <div className="row py-3">
                        <div className="col-md-12 text-center">
                            <div className="w-100 ">
                                <img src={`${BASE_URL}${state?.image}`} alt='image' className='img-fluid rounded shadow ' style={{height:"300px", objectFit:"cover"}}/>
                            </div>
                        </div>
                        <div className="col-md-12 pt-4">
                            <div className="w-100 shadow p-3 round">
                                <div className="row">
                                    <div className="text">
                                        <div className="d-flex align-items-center justify-content-between ">
                                            <h5 className='text-blue'>
                                                {state?.name}
                                            </h5>
                                            <div className="d-flex gap-3 align-items-center">

                                                <h6 className='text-black fw-bold'>Minimum Purchase:<span className='text-blue'> {state?.minimum_purchase}</span></h6>
                                            </div>
                                        </div>
                                        <p className='details'>
                                            {state?.description}

                                        </p>
                                        <p className='details'>
                                            {state?.state} , {state?.city}
                                        </p>
                                    </div>

                                </div>
                                <div className="div d-flex pt-2 pb-2 align-items-center gap-3">
                                    <div className="icon text-blue">
                                        <i class="fa-regular fa-calendar"></i>
                                    </div>
                                    <div className="text date ">
                                        <p className='datepara mb-0 text-blue'>
                                            {new Date(state?.start_date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' })} to {new Date(state?.expiry_date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}
                                        </p>
                                    </div>
                                </div>
                                {/* <p>
                                    Valid on : 5 Days
                                </p> */}
                                <p className="" dangerouslySetInnerHTML={{ __html: state?.offer_detail }}></p>
                                <div class="accordion" id="accordionExample">
                                    <div className="div py-2">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Redeem Information
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <ul>
                                                        <li className="" dangerouslySetInnerHTML={{ __html: state?.offer_redeem }}></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div py-b">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Terms & Conditions
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <ul>
                                                        <li className="" dangerouslySetInnerHTML={{ __html: state?.offer_terms }}></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="w-100">

                            </div>
                        </div>
                    </div>


                </div>
            </section>


        </>
    )
}

export default Offerdetails
