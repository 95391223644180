import React, { useEffect, useState } from 'react'
// import Table from '../../Component/Table'

// import moment from 'moment/moment'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import axios from 'axios'
import { getwithheader } from '../../Api/Apis'
import { BASE_URL } from '../../URL'
import TitleComp from '../../component/TitleComp'
import moment from 'moment/moment'
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
// import Loader from '../../Component/Loader'

function EventList() {

    const [data, setdata] = useState([])
    const [loading, setloading] = useState(false)
    const handleget = async () => {
        const res = await getwithheader('events')
        console.log(res.data)
        setdata(res.data)
    }
    const token = localStorage.getItem("token")
    const headers = {
        Authorization: `Bearer ${token}`
    }

    const navigate = useNavigate()



    useEffect(() => {
        handleget()
    }, [])



    const columns = [
        {
            field: "image",
            headerName: "Image",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <img src={`${BASE_URL}${params.row.image}`} style={{ objectFit: "cover", height: "50px", width: "50px", borderRadius: "50%" }} alt="" />
                    </>
                )
            }
        },

        {
            field: "title",
            headerName: "Title",
            flex: 1,
        },
        {
            field: "short_detail",
            headerName: "short_detail",
            flex: 1,
        },
        {
            field: "date",
            headerName: "Date",
            flex: 1,
            rendercell: (params) => {
                return (
                    <>
                        <span>{moment(params.row.date).format('DD-MM-YYYY HH:mm')}</span>
                    </>
                )
            }
        },
        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="d-flex gap-3 mt-1">
                        <button className="btn bg-yellow text-white" onClick={(e) => handleedit(e, params.row)}>
                            <i className="fa-solid fa-pen-to-square"></i>
                        </button>

                    </div>
                );
            },
        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="d-flex gap-3 mt-1">

                        <button className="btn bg-yellow text-white" onClick={(e) => handledelete(e, params.row._id)}>
                            <i className="fa-solid fa-trash"></i>
                        </button>
                    </div>
                );
            },
        },







    ]
    const handledelete = async (e, deleid) => {
        e.preventDefault()
        const isConfirmed = window.confirm("Are you sure you want to delete this event?");
        if (!isConfirmed) {
            return;
        }
        setloading(true)
        let requestdata = {
            data: {
                id: deleid
            }
        }
        axios.delete(`${BASE_URL}events`, requestdata, { headers }).then((res) => {
            console.log(res)
            if (res.data.error == "0") {
                toast.success(res.data.message)
                handleget()
                setloading(false)
            }
        }).catch((e) => {
            toast.error(e.data.message)
            setloading(false)
        })

    }

    const handleedit = (e, item) => {
        e.preventDefault()
        navigate('/addevent', { state: item })
    }


    const getRowId = (row) => row._id
    return (
        <>
            {/* {loading && <Loader />} */}

            <>
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center">
                            <TitleComp title="Event List" />
                        </div>
                    </div>
                    <div className="col-md-12 px-4">
                        <Box>
                            <DataGrid rows={data} columns={columns} getRowId={getRowId} />
                        </Box>
                        {/* <Table data={data} columns={columns} getRowId={getRowId} /> */}
                    </div>
                </div>
            </>

        </>
    )
}

export default EventList
