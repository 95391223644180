import React, { useEffect, useState } from 'react'
import TitleComp from '../../component/TitleComp'
import { getwithheader } from '../../Api/Apis';
import { BASE_URL } from '../../URL';
import moment from 'moment';

const Couponslist = () => {
    const [loading, setloading] = useState(false)
    const [data, setdata] = useState([]);
    const [couponstaus, setcouponstaus] = useState("used")

    const handlegetcoupons = async () => {
        setloading(true)
        let res = await getwithheader(`generate-code?status=${couponstaus}`)
        console.log(res.data);
        if (res.error == "0") {
            setdata(res.data)
            setloading(false)
        } else {
            setloading(false)
        }
    }


    useEffect(() => {

        handlegetcoupons()
    }, [couponstaus])
    return (
        <div>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <TitleComp title="Generated Codes " />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-end">
                        <div className="col-md-4">
                            <label htmlFor="couponStatus">Coupon Status</label>
                            <select name=""
                                value={couponstaus}
                                onChange={(e) => setcouponstaus(e.target.value)}
                                className='form-control form-select'
                                id="">
                                <option value="">Select Coupon Status</option>
                                <option value="used">Used</option>
                                <option value="expired">Expired</option>
                                <option value="active">Active</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        {data.length > 0 ? (
                            data.map((itm, index) => (
                                <div key={index} className="col-md-6 pt-3">
                                    <div className="w-100 rounded shadow p-3">
                                        <div className="row">
                                            {/* Offer Image */}
                                            <div className="col-md-4">
                                                <img
                                                    src={
                                                        itm.offer?.image
                                                            ? `${BASE_URL}${itm.offer.image}`
                                                            : '/placeholder.jpg'
                                                    }
                                                    alt="Offer"
                                                    className="img-fluid"
                                                    style={{ objectFit: 'cover' }}
                                                />
                                            </div>

                                            {/* Offer Details */}
                                            <div className="col-md-8">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h6 className="fw-bold text-blue">Code</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="fw-bold" style={{ color: '#eb6722' }}>
                                                            {itm.code}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h6 className="fw-bold text-blue">User Name</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p>{itm.user?.name}</p>
                                                    </div>
                                                </div>
                                                {
                                                    itm.vendor?.name && (
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="fw-bold text-blue">Vendor Name</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{itm.vendor.name}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-12 mt-2">
                                                        <h4>OFFER</h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h6 className="fw-bold text-blue">Offer Name</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p>{itm.offer?.name}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h6 className="fw-bold text-blue">Start Date</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p>{itm.offer?.start_date
                                                            ? moment(itm.offer.start_date).format('DD MMMM YYYY')
                                                            : "N/A"}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h6 className="fw-bold text-blue">Expiry Date</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p>{itm.offer?.start_date
                                                            ? moment(itm.offer.expiry_date).format('DD MMMM YYYY')
                                                            : "N/A"}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h6 className="fw-bold text-blue">Usage Limit</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p>{itm.offer?.usage_limit}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <h6 className='text-center text-uppercase'>{couponstaus} - Data not Found</h6>
                        )}
                    </div>

                </div>
            </section>
        </div>
    )
}

export default Couponslist
