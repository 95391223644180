import React from 'react'
import userbg from '../../assests/userbg.jpg'
import userface from '../../assests/userface.png'
import cross from '../../assests/redcross.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../URL'
import { putwithheader } from '../../Api/Apis'
import { toast } from 'react-toastify'
import correct from '../../assests/correct.png'
import pending from '../../assests/pending.png'

const Userdetails = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem("paneltoken")
    // Access state passed via navigation
    const location = useLocation();
    const { state } = location; // Destructure state object
    const handlechangestatus = async (e, id, name) => {
        e.preventDefault()
        let formdata = new FormData()
        formdata.append("_id", id)
        if (name == "delete") {
            formdata.append("status", "cancelled")
        } else {
            formdata.append("status", name)
        }
        formdata.append("deleted_at", new Date())
        const res = await putwithheader("updateuser", formdata, token)
        if (res.error == "0") {
            toast.success(res.message)
            navigate('/userlist')

        } else {
            toast.error("Data not Update")
        }

    }
    return (
        <div>
            <section className='userdetails'>
                <div className="container">
                    <div className="row ">
                        <div className="col-md-12">
                            <img src={userbg} alt='image' className='img-fluid w-100 ' style={{ height: "300px" }} />
                        </div>
                    </div>
                    <div className="row detailsrow ">
                        <div className="col-md-12" style={{ position: "relative", bottom: "70px" }}>
                            <div className="row bg-white shadow rounded-4">
                                <div className="col-md-2">
                                    <div class="m-2  rounded-5 bg-white shadow p-1 userfacebox" >
                                        <div class="bg-slate-300 p-3 rounded-5 userfaceboxinner">
                                            <img
                                                src={`${BASE_URL}${state.image}`}
                                                alt="User"
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite fallback loop
                                                    e.target.src = userface; // Fallback image
                                                }}
                                                style={{
                                                    height: "5rem",
                                                    width: "5rem",
                                                    borderRadius: "50%",
                                                    objectFit: "cover", // Optional for better image fitting
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div class="mt-3">
                                        <div class="ps-3 mt-2">
                                            <h3 class="text-4xl capitalize">{state?.name}</h3>
                                        </div>
                                        <div class="ps-3 mt-2 d-flex align-items-center gap-2">
                                            <h6 class=" capitalize m-0">Status :</h6>
                                            {state?.status === "cancelled" && (
                                                <img src={cross} alt="Cancelled" class="img-fluid" width="50" />
                                            )}
                                            {state?.status === "accepted" && (
                                                <img src={correct} alt="Accepted" class="img-fluid" width="50" />
                                            )}
                                            {state?.status === "pending" && (
                                                <img src={pending} alt="Pending" class="img-fluid" width="50" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div class="mt-4 grid gap-x-8 gap-y-4 grid-cols-2">
                                        <button class=" hover:bg-blue-600 text-white font-medium py-2 px-4 rounded text-base resetbtn" onClick={(e) => handlechangestatus(e, state._id, "cancelled")}>Cancelled</button>
                                        <button class=" hover:bg-red-600 text-white font-medium py-2 px-4 rounded text-base rejectbtn" onClick={(e) => handlechangestatus(e, state._id, "delete")}>Delete</button>
                                        <button class=" hover:bg-green-600 text-white font-medium py-2 px-4 rounded text-base approvebtn" onClick={(e) => handlechangestatus(e, state._id, "accepted")}>Accept</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row detailsrow pb-4">
                        <div className="col-md-6 ">
                            <div class="shadow bg-white rounded-2">
                                <div class="p-3 rounded-2" style={{ background: "#e7e5e4" }}>
                                    <h3 class="text-3xl  font-normal" >User Information</h3>
                                </div>
                                <div class="p-3">
                                    <div class="mb-2 row d-flex justify-content-between">
                                        <div className="info col-md-6">
                                            <h6 class=" fw-bold">Name</h6>
                                        </div>
                                        <div className="details col-md-6">
                                            <h6>{state?.name}</h6>
                                        </div>
                                    </div>
                                    <div class="mb-2 row d-flex justify-content-between ">
                                        <div className="info col-md-6">
                                            <h6 class="fw-bold ">Email</h6>
                                        </div>
                                        <div className="details col-md-6">
                                            <h6>{state?.email}</h6>
                                        </div>
                                    </div>
                                    <div class="mb-2 row d-flex justify-content-between">
                                        <div className="info col-md-6">
                                            <h6 class="fw-bold ">Mobile</h6>
                                        </div>
                                        <div className="details col-md-6">
                                            <h6>{state?.mobile}</h6>
                                        </div>
                                    </div>

                                    <div class="mb-2 row d-flex justify-content-between">
                                        <div className="info col-md-6">
                                            <h6 class=" fw-bold">Address</h6>
                                        </div>
                                        <div className="details col-md-6">
                                            <h6>{state?.fulladdress}</h6>
                                        </div>
                                    </div>
                                    <div class="mb-2 row d-flex justify-content-between">
                                        <div className="info col-md-6">
                                            <h6 class=" fw-bold">Type</h6>
                                        </div>
                                        <div className="details col-md-6">
                                            <h6>{state?.type}</h6>
                                        </div>
                                    </div>
                                    {state?.gst && (
                                        <div class="mb-2 row d-flex justify-content-between">
                                            <div className="info col-md-6">
                                                <h6 class="fw-bold">Gst</h6>
                                            </div>
                                            <div className="details col-md-6">
                                                <h6>{state?.gst}</h6>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Userdetails
